<template>
  <div class="profile-data-wrapper manage-settings">
    <div class="manage-tabs">
      <ul class="nav">
        <li class="nav-item">
          <a
            href="javascript:void(0);"
            @click="goToRoute('ManageCategories')"
            class="links"
            :class="{ active: $route.name === 'ManageCategories' }"
            >Categories</a
          >
        </li>
        <li class="nav-item">
          <a
            href="javascript:void(0);"
            @click="goToRoute('ManageSubCategories')"
            class="links"
            :class="{ active: $route.name === 'ManageSubCategories' }"
            >Sub Categories</a
          >
        </li>
        <li class="nav-item">
          <a
            href="javascript:void(0);"
            @click="goToRoute('ManageExpertise')"
            class="links"
            :class="{ active: $route.name === 'ManageExpertise' }"
            >Expertise</a
          >
        </li>
        <li class="nav-item">
          <a
            href="javascript:void(0);"
            @click="goToRoute('ManageSkills')"
            class="links"
            :class="{ active: $route.name === 'ManageSkills' }"
            >Skills</a
          >
        </li>
      </ul>
    </div>
  </div>
  <router-view></router-view>
</template>
<script>
import common from "../mixins/common";
export default {
  mixins: [common],
};
</script>
